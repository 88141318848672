@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  color: white;
  text-decoration: none;
}
body {
  font-family: "Poppins", sans-serif;
}
nav ul {
  padding: 0;
  margin: 0;
}
nav ul li{
  cursor: pointer;
}
.background{
  background-image: url(./Images/features-bg.jpg);
  background-size: cover;
  background-position: center;
}
.hero-section {
  min-height: 100vh;
}
.hero-section h1 span{
  font-family:  'Orbitron', sans-serif  ;
}
.hero-image{
  margin: 1px auto;
}
.logo-image{
  max-width: 45% !important;
  margin: 1px auto;
}
.features-section h2, .work-section h2 {
  color: #F1C40F;
}
.hero-section h1{
  color:#8E44AD;
}

.hero-subtitle {
  max-width: 30rem;
  margin: 2.5rem auto;
  line-height: 1.6;
  letter-spacing: -0.01rem;
  color: rgba(0, 0, 0, 0.708);
}
.work-subtitle {
  max-width: 40rem;
  margin: 1rem auto 0px;
  line-height: 1.6;
  text-align: left;
  letter-spacing: -0.01rem;
}
button {
  width: 18rem;
  margin-top: 10px;
  padding-top: 1.38rem;
  padding-bottom: 1.38rem;
}
.btn:hover{
  outline: 2px solid #2ecc71;
}
.feature-content {
  border: 1px solid black;
  padding: 40px 5rem;
  border-radius: 30px;
  max-width: 70%;
  margin: 1rem auto 0px;
  line-height: 1.6;
  text-align: left;
  letter-spacing: -0.01rem;
  background-color: #2ecc71;
}
.btn{
  background-color: #2ecc71;
  font-size: 24px;
  font-weight: 900;
  padding: 10px 20px;
}
@media screen and (max-width: 750px) {
  .feature-content {
    padding: 40px 3rem;
  }
  .btn{
    font-size: 16px;
    padding: 8px 16px;
  }
  .hero-subtitle {
    margin: 1.5rem auto;
  }
  .logo-image{
    max-width: 75% !important;
  }
}
@media screen and (max-width: 550px) {
  .feature-content {
    padding: 20px 1rem;
    max-width: 90%;
  }
  .btn{
    font-size: 12px;
    padding: 8px 16px;
  }
  .logo-image{
    max-width: 95% !important;
  }
}
