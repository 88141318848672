/* .login-container {
  background-image: url(../Images/features-bg.jpg);
  background-size: cover;
  background-position: center;
} */
.login__content {
  align-items: center;
  display: grid;
  min-height: 100vh;
}
.login__form {
  display: grid;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.06);
  margin-inline: 1.5rem;
  row-gap: 1.25rem;
  backdrop-filter: blur(20px);
  padding: 2rem 2rem 1rem 2rem;
  margin: 2rem;
  border-radius: 1rem;
}
.login__title {
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.login__title span {
  color: #2ecc71;
}

.login__description {
  font-size: 0.813rem;
  color: rgb(0, 0, 0);
}

.login__inputs {
  display: grid;
  row-gap: 0.75rem;
  margin-bottom: 0.5rem;
}
.login__label {
  display: block;
  color: black;
  font-size: 0.813rem;
  font-weight:600;
  margin-bottom: 0.25rem;
}

.login__input {
  width: 100%;
  padding: 14px 12px;
  border-radius: 6px;
  border: 2px solid black;
  background-color: rgba(255, 255, 255, 0.1);
  color: black;
  font-size: 0.75rem;
  font-weight: 500;
  transition: border 0.4s;
}

.login__input::placeholder {
  color: black;
}

.login__input:focus,
.login__input:valid {
  border: 2px solid #2ecc71;
}

.login__box {
  position: relative;
}
.login__input {
  padding-right: 36px;
}

.login__eye {
  width: max-content;
  height: max-content;
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  font-size: 1.25rem;
  color: black;
  cursor: pointer;
}

.login__check {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}
.login__check-input {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid black;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
}

.login__check-input:checked {
  background: #2ecc71;
}

.login__check-input:checked::before {
  content: "✓";
  display: block;
  color: #fff;
  font-size: 0.75rem;
  transform: translate(1.5px, -2.5px);
}

.login__check-label {
  font-size:  0.813rem;
  color: black;
}

.login__buttons {
  display: flex;
  column-gap: 0.75rem;
}
.login__button {
  width: 100%;
  padding: 14px 2rem;
  border-radius: 6px;
  background: linear-gradient(180deg, #2ecc71, #2caa60);
  color: #fff;
  font-size: 0.813rem;
  font-weight: 600;
  box-shadow: 0 6px 24px hsla(149, 75%, 48%, 0.5);
  margin-bottom: 1rem;
  cursor: pointer;
}

.login__button-ghost {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #2ecc71;
  color: #2ecc71;
  box-shadow: none;
}

.login__forgot {
  font-size: 0.75rem;
  font-weight: 600;
  color: #2ecc71;
  text-decoration: none;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 360px) {
  .login__buttons {
    flex-direction: column;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .login__form {
    width: 450px;
    justify-self: center;
  }
}

/* For large devices */
@media screen and (min-width: 1064px) {
  .login-container {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  .login__img {
    border-radius: 3.5rem;
    box-shadow: 0 24px 48px #024361;
  }

  .login__form {
    justify-self: flex-end;
    margin-right: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .login__form {
    row-gap: 2rem;
    padding: 3rem;
    border-radius: 1.25rem;
    border: 2.5px solid rgba(255, 255, 255, 0.06);
  }

  .login__description,
  .login__label,
  .login__button {
    font-size: 1rem;
  }

  .login__inputs {
    row-gap: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .login__input {
    border: 2.5px solid black;
    padding: 1rem;
    font-size: 0.813rem;
  }

  .login__input:focus,
  .login__input:valid {
    border: 2.5px solid #2ecc71;
  }

  .login__button {
    padding-block: 1rem;
    margin-bottom: 1.25rem;
  }

  .login__button-ghost {
    border: 2.5px solid #2ecc71;
  }
}

@media screen and (min-width: 1450px) {
}

@media screen and (min-width: 2000px) {
  .login__form {
    height: 700px;
    width: 700px;
  }
}
